
const trackEvent = (category: string, action: string, name: string) => {
    if (window._paq) {
        window._paq.push(['trackEvent', category, action, name])
    }
}

export const useAnalytics = () => {

    return { trackEvent }
}